interface TeaserHeadingProps {
  children: React.ReactNode;
}

const TeaserHeading: React.FC<TeaserHeadingProps> = ({ children }) => (
  <h2
    className="
      text-xl font-black transition-all duration-400 ease-out
      [text-shadow:_0px_24px_48px_rgba(16,_24,_40,_0.18),_0px_0px_24px_rgba(16,_24,_40,_0.08)]
      tablet:text-2xl group-hover:laptop:text-text
    "
  >
    {children}
  </h2>
);

export default TeaserHeading;
