"use client";

import BestPrice from "@/app/_media/svg/best-price.svg";
import { LinkOrAnchor } from "@/app/_components/link";

import Button from "@/app/_components/button";
import TeaserImage from "@/app/_components/TeaserImage/TeaserImage";
import SolaranlageImage from "./_media/Solar-lizensiert.png";
import WaermepumpeImage from "./_media/Waermepumpe-lizensiert.png";

import TeaserBackground from "./TeaserBackground";
import TeaserContent from "./TeaserContent";
import TeaserGradient from "./TeaserGradient";
import TeaserHeading from "./TeaserHeading";
import TeaserItem from "./TeaserItem";
import TeaserTags from "./TeaserTags";
import TeaserTag from "./TeaserTag";

const Teaser = () => (
  <div
    className="
        flex flex-col gap-2
        tablet:gap-3
        laptop:grid laptop:h-[22.125rem] laptop:grid-cols-[2fr_2fr] laptop:gap-8
      "
  >
    <TeaserItem>
      <LinkOrAnchor href="/solarrechner/">
        <TeaserBackground
          className="
            group-hover:laptop:ml-[340px] group-hover:laptop:w-[calc(100%-340px)]
            group-hover:desktop:ml-[364px] group-hover:desktop:w-[calc(100%-364px)]
          "
        >
          <TeaserGradient
            className="
              bg-gradient-to-b from-[rgba(3,_1,_28,_0.43)_0%] to-[rgba(3,_1,_28,_0.00)_77.34%]
              laptop:from-[rgba(3,_1,_28,_0.48)_37.35%] laptop:to-[rgba(3,_1,_28,_0.00)_91.71%] laptop:bg-gradient-[74deg]
            "
          />
          <TeaserImage
            alt="Solaranlage auf einem Hausdach"
            src={SolaranlageImage}
            className="tablet:object-[50%_31%] laptop:object-[5%_50%] group-hover:laptop:object-[56%_50%]"
          />
        </TeaserBackground>
        <TeaserContent className="h-full tablet:py-10">
          <BestPrice
            className="
              absolute right-4 top-4 h-16 fill-bluegray-1000
              transition-all duration-400
              ease-out tablet:right-5 tablet:top-10 laptop:bottom-8 laptop:right-3
              laptop:top-auto group-hover:laptop:opacity-0
              desktop:right-6
              group-hover:desktop:opacity-100
              [&>.logo-best-price-bg]:backdrop-blur-[2px]
            "
          />
          <div className="flex h-full flex-col items-start gap-2 tablet:gap-4 laptop:justify-end">
            <TeaserHeading>Solarrechner</TeaserHeading>
            <TeaserTags>
              <TeaserTag>Optimale Anlagengröße ermitteln</TeaserTag>
              <TeaserTag>Unverbindliche Angebote in 2 min</TeaserTag>
              <TeaserTag>Preise & Montagetermine vergleichen</TeaserTag>
            </TeaserTags>
            <Button className="grow-0" size="large" variant="primary">
              Ersparnis berechnen
            </Button>
          </div>
        </TeaserContent>
      </LinkOrAnchor>
    </TeaserItem>

    <TeaserItem>
      <LinkOrAnchor href="/waermepumpenrechner/">
        <TeaserBackground
          className="
            group-hover:laptop:ml-[340px] group-hover:laptop:w-[calc(100%-340px)]
            group-hover:desktop:ml-[364px] group-hover:desktop:w-[calc(100%-364px)]
          "
        >
          <TeaserGradient
            className="
              bg-gradient-to-b from-[rgba(3,_1,_28,_0.43)_0%] to-[rgba(3,_1,_28,_0.00)_77.34%]
              laptop:from-[rgba(3,_1,_28,_0.48)_37.35%] laptop:to-[rgba(3,_1,_28,_0.00)_91.71%] laptop:bg-gradient-[74deg]
            "
          />
          <TeaserImage
            alt="Solaranlage auf einem Hausdach"
            src={WaermepumpeImage}
            className="tablet:object-[50%_31%] laptop:object-[5%_50%] group-hover:laptop:object-[56%_50%]"
          />
        </TeaserBackground>

        <TeaserContent className="h-full overflow-hidden tablet:py-10">
          <div
            className="
              absolute right-[-75px] top-0 flex w-[224px]
              items-center py-2 text-center text-sm font-black text-tertiary-500
              tablet:text-base laptop:py-2.5 laptop:text-xl
            "
          >
            <div className="relative w-full rotate-[36deg] bg-white py-2">
              NEU
            </div>
          </div>
          <div className="flex h-full flex-col items-start gap-2 tablet:gap-4 laptop:justify-end">
            <TeaserHeading>Wärmepumpen-Rechner</TeaserHeading>
            <TeaserTags>
              <TeaserTag>Benötigte Heizleistung ermitteln</TeaserTag>
              <TeaserTag>Preise & Serviceleistungen vergleichen</TeaserTag>
              <TeaserTag>Anbieter kostenlos anfragen </TeaserTag>
            </TeaserTags>
            <Button className="grow-0" size="large" variant="primary">
              Angebote vergleichen
            </Button>
          </div>
        </TeaserContent>
      </LinkOrAnchor>
    </TeaserItem>
  </div>
);

export default Teaser;
