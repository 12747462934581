"use client";

import Image from "next/image";
import tuvBadge from "@/public/tuev-webcheck-badge.png";
import heyDataBadge from "@/app/_media/Trust_Badges_HeyData.png";

const SocialProof = () => (
  <div className="flex items-center gap-5 p-4 tablet:gap-8 tablet:p-0 laptop:gap-10">
    <Image
      className="h-12 w-12 laptop:h-16 laptop:w-16"
      width={120}
      height={120}
      src={tuvBadge}
      alt="TÜV Saarland Webcheck"
    />
    <Image
      className="h-12 w-12 laptop:h-16 laptop:w-16"
      width={120}
      height={120}
      src={heyDataBadge}
      alt="TÜV Saarland Webcheck"
    />
    <div className="hidden tablet:block">
      {/* Google Reviews */}
      <div className="reviewforest-app-daeee7b0-649f-46d8-aecf-0f3f9d4adb89 !hidden empty:!hidden tablet:!flex" />
    </div>

    <div className="flex flex-col font-black leading-6 tablet:block tablet:w-1/2">
      Finanztip hat unseren Solar-Vergleich getestet und empfiehlt!
    </div>
  </div>
);

export default SocialProof;
