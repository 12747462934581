import classNames from "classnames";

interface TeaserGradientProps {
  className?: string;
}

const TeaserGradient: React.FC<TeaserGradientProps> = ({ className }) => (
  <div
    className={classNames(
      "absolute z-20 h-full w-full transition-all duration-400 ease-out group-hover:laptop:opacity-0",
      className,
    )}
  />
);

export default TeaserGradient;
