import classNames from "classnames";
import { twMerge } from "tailwind-merge";

interface TeaserContentProps {
  children: React.ReactNode;
  className?: string;
}

const TeaserContent: React.FC<TeaserContentProps> = ({
  children,
  className,
}) => (
  <div
    className={twMerge(
      classNames(
        `
        relative z-20
        flex flex-col items-start
        p-4 tablet:px-5 tablet:py-6
        laptop:justify-end
        laptop:px-3 laptop:py-8
        desktop:px-6
      `,
        className,
      ),
    )}
  >
    {children}
  </div>
);

export default TeaserContent;
