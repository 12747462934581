/* eslint-disable react/require-default-props */
/* https://github.com/jsx-eslint/eslint-plugin-react/issues/2856 */

"use client";

import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { TagProps, TagsContextProvider } from "./useTags";

interface TagsProps extends TagProps {
  children: React.ReactNode;
  className?: string;
}

const Tags = forwardRef<HTMLUListElement, TagsProps>(
  ({ children, className = "", ...props }, ref) => (
    <ul
      className={twMerge(
        "flex flex-wrap justify-start gap-x-4 gap-y-2",
        className,
      )}
      ref={ref}
    >
      <TagsContextProvider value={props}>{children}</TagsContextProvider>
    </ul>
  ),
);

Tags.displayName = "Tags";

export default Tags;
